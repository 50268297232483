import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CurrentDate from "../date/Date.js";
import * as actions from "../../store/actions/index";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "./Home.css";
import liveIndicator from "../../assets/images/live-indicator.gif";

localStorage.removeItem("backlogArrivalsBoardFetch");
localStorage.removeItem("nextArrivalsBoardFetch");

const Home = (props) => {
  const {
    arrivalsBoardData,
    onGetArrivalsBoardInfo,
    location,
    onFetchUserList,
    userList,
    notifications,
    controlRoomList,
    onFetchControlRoomList,
    isConnectedCheck,
  } = props;

  //const [notify, setNotify] = useState(false);
  const [searchOptions, setSearchOptions] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tooltipShowDelay] = useState(0);
  const [isSocketConnect, setIsSocketConnect] = useState(false);
  const [xApiKeyParam, setXApiKeyParam] = useState(null);
  const [timeParam, setTimeParam] = useState(null);
  const [roomParam, setRoomParam] = useState(null);
  const [perPageParam, setPerPageParam] = useState(null);
  const [refreshParam, setRefreshParam] = useState(null);
  const [filterParam, setFilterParam] = useState(false);
  const [liveParam, setLiveParam] = useState("true");
  const [panel, setPanel] = useState(true);
  const [abstractLiveshotNameParam, setAbstractLiveshotNameParam] =
    useState(null);
  const [stIdParam, setStIdParam] = useState(null);
  const [title, setTitle] = useState(null);
  const [controlRoomViewParam, setControlRoomViewParam] = useState(false);
  const [timeFormat12Hour, setTimeFormat12Hour] = useState(false);
  const [debounceInterval, setDebounceInterval] = useState(1500);
  const [filteredArrivalData, setFilteredArrivalData] = useState();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isConnectedCheckValue, setIsConnectedCheckValue] = useState();
  const breakpoint = 769;

  useEffect(() => {
    setIsConnectedCheckValue(isConnectedCheck);
  }, [isConnectedCheck]);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    if (notifications && liveParam === "true") {
      if (
        notifications.type === "LIVESHOT" ||
        notifications.type === "ABSTRACTLIVESHOT" ||
        notifications.type === "LIVESHOTSIGNAL"
      ) {
        for (let arrivalInfo of arrivalsBoardData) {
          if (notifications.data.liveshotId) {
            if (arrivalInfo.liveshotId === notifications.data.liveshotId) {
              onGetArrivalsBoardInfo(
                xApiKeyParam,
                timeParam,
                roomParam,
                perPageParam,
                userList,
                abstractLiveshotNameParam,
                stIdParam,
                timeFormat12Hour,
                debounceInterval
              );
            }
          } else if (notifications.data.abstractLiveshotId) {
            if (
              arrivalInfo.abstractLiveshotId ===
              notifications.data.abstractLiveshotId
            ) {
              onGetArrivalsBoardInfo(
                xApiKeyParam,
                timeParam,
                roomParam,
                perPageParam,
                userList,
                abstractLiveshotNameParam,
                stIdParam,
                timeFormat12Hour,
                debounceInterval
              );
            }
          }
        }
      }
      if (notifications.type === "CANVAS") {
        for (let arrivalInfo of arrivalsBoardData) {
          if (notifications.data.canvasId) {
            if (arrivalInfo.canvasId === notifications.data.canvasId) {
              onGetArrivalsBoardInfo(
                xApiKeyParam,
                timeParam,
                roomParam,
                perPageParam,
                userList,
                abstractLiveshotNameParam,
                stIdParam,
                timeFormat12Hour,
                debounceInterval
              );
            }
          } else {
            if (notifications.data) {
              if (arrivalInfo.canvasId === parseInt(notifications.data)) {
                onGetArrivalsBoardInfo(
                  xApiKeyParam,
                  timeParam,
                  roomParam,
                  perPageParam,
                  userList,
                  abstractLiveshotNameParam,
                  stIdParam,
                  timeFormat12Hour,
                  debounceInterval
                );
              }
            }
          }
        }
      }
    }
    if (roomParam && roomParam !== null) {
      setColumns([
        {
          headerName: "Block",
          field: "blockName",
          resizable: true,
          sortable: true,
          cellStyle: { "text-align": "center" },
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          flex: 2,
        },
        {
          headerName: "Window",
          field: "window",
          resizable: true,
          sortable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          flex: 4,
        },
        {
          headerName: "Hit Time",
          field: "hitTimevalue",
          resizable: true,
          sortable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          flex: 2,
        },
        {
          headerName: "Location",
          field: "location",
          resizable: true,
          sortable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          flex: 6,
        },
        {
          headerName: "Liveshot Name",
          field: "abstractLiveshotName",
          resizable: true,
          sortable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          tooltipField: "abstractLiveshotIdToolTip",
          flex: 6,
        },
        {
          headerName: "IFB",
          field: "comms",
          resizable: true,
          sortable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          flex: 3,
        },
        {
          headerName: "Global Source",
          field: "global",
          resizable: true,
          sortable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          tooltipField: "abstractLiveshotUpdated",
          flex: 4,
        },
        {
          headerName: "Source",
          field: "local",
          resizable: true,
          sortable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          tooltipField: "liveshotUpdated",
          flex: 3,
        },
        {
          headerName: "Ready",
          field: "ready",
          cellClass: "ready-cell",
          resizable: true,
          sortable: true,
          cellStyle: { border: "none !important", "text-align": "center" },
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          flex: 2,
        },
      ]);
    }
  }, [
    notifications,
    liveParam,
    arrivalsBoardData,
    onGetArrivalsBoardInfo,
    xApiKeyParam,
    timeParam,
    roomParam,
    perPageParam,
    userList,
    abstractLiveshotNameParam,
    stIdParam,
    timeFormat12Hour,
    debounceInterval,
  ]);
  const [defaultControlRoomColumns] = useState([
    {
      headerName: "Hit Time",
      field: "hitTimevalue",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 3,
      // cellClassRules:{
      //   "text-gray": function (params) {
      //     return params.data.canceled === true;
      //   },
      //   "text-blue": function (params) {
      //     return params.data.readyForPcr === true;
      //   },
      //   "text-green": function (params) {
      //     return params.data.readyForAir === true;
      //   },
      //   "text-white": function (params) {
      //     return params.data.needsMediaTrafficReviewFlag === true;
      //   },
      // }
    },
    {
      headerName: "Liveshot Name",
      field: "abstractLiveshotName",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "abstractLiveshotIdToolTip",
      flex: 6,
    },
    {
      headerName: "Location",
      field: "location",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 5,
    },
    {
      headerName: "IFB",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 5,
      cellStyle: { textAlign: "center", border: "none !important" },
      cellRendererFramework: (Params) => {
        return Params.data.comms ? (
          <div className="ifb-cell">
            <span> {Params.data.comms}</span>
          </div>
        ) : null;
      },
    },
    {
      headerName: "Global Source",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "abstractLiveshotUpdated",
      flex: 5,
      cellStyle: { textAlign: "center", border: "none !important" },
      cellRendererFramework: (Params) => {
        return Params.data.global ? (
          <div className="ifb-cell">
            <span> {Params.data.global}</span>
          </div>
        ) : null;
      },
    },
    {
      headerName: "RS",
      resizable: true,
      sortable: true,
      tooltipField: "liveshotUpdated",
      cellStyle: { border: "none !important", "text-align": "center" },
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 2,
      cellRendererFramework: (Params) => {
        return Params.data.sourceValue ? (
          <div className="ifb-cell">
            <span> {Params.data.sourceValue}</span>
          </div>
        ) : null;
      },
    },
    {
      headerName: "Status",
      cellClass: "ready-cell",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 4,
      cellStyle: { textAlign: "center", border: "none !important" },
      cellRendererFramework: (Params) => {
        return (
          <div
            className={
              Params.data.canceled === true
                ? "text-gray-source-cell"
                : Params.data.readyForPcr === true
                ? "text-blue-source-cell"
                : Params.data.readyForAir === true
                ? "text-green-source-cell"
                : Params.data.clear === true
                ? "text-orange-source-cell"
                : Params.data.needsMediaTrafficReviewFlag === true
                ? "text-white-source-cell"
                : ""
            }
          >
            <span>{Params.data.ready ? Params.data.ready : ""}</span>
          </div>
        );
      },
    },
  ]);

  const [mobileColumns] = useState([
    {
      headerName: "CR",
      field: "roomCode",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 2,
    },
    {
      headerName: "Hit Time",
      field: "hitTimevalue",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 2,
    },
    {
      headerName: "Liveshot Name",
      field: "abstractLiveshotName",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "abstractLiveshotIdToolTip",
      flex: 6,
    },
    {
      headerName: "IFB",
      field: "comms",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 3,
    },
    {
      headerName: "Ready",
      field: "ready",
      cellClass: "ready-cell",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important", "text-align": "center" },
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 2,
    },
  ]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const allParams = {};
    for (let param of query.entries()) {
      allParams[param[0]] = param[1];
    }
    setSearchOptions(allParams);
  }, [location.search]);

  useEffect(() => {
    if (searchOptions) {
      let xApiKey = null;
      if (searchOptions.xApiKey) {
        xApiKey = searchOptions.xApiKey;
      }
      onFetchUserList(xApiKey);
      onFetchControlRoomList();
    }
  }, [onFetchUserList, searchOptions, onFetchControlRoomList]);

  useEffect(() => {
    if (searchOptions) {
      let time = null;
      let room = null;
      let perPage = null;
      let xApiKey = null;
      let refresh = null;
      let live = null;
      let abstractLiveshotName = null;
      let stId = null;
      let view = null;
      let timeFormat = null;
      let timeFormatValue = null;
      let debounceIntervalValue = null;
      if (searchOptions.xApiKey) {
        xApiKey = searchOptions.xApiKey;
        setXApiKeyParam(xApiKey);
      }
      if (searchOptions.time) {
        time = searchOptions.time;
        setTimeParam(time);
      }
      if (searchOptions.room) {
        room = searchOptions.room;
        setRoomParam(room);
        const roomId = Number(room);
        let roomCode = controlRoomList
          .filter((list) => list.roomId === roomId)
          .map((room) => {
            return room.roomCode;
          });
        if (roomCode.length > 0) {
          setTitle("LIVE SHOT MANAGER ARRIVALS: " + roomCode[0]);
        } else {
          setTitle("LIVE SHOT MANAGER ARRIVALS:");
        }
      } else {
        setTitle("LIVE SHOT MANAGER ARRIVALS: ROCK CENTER");
      }
      if (searchOptions.perPage) {
        perPage = searchOptions.perPage;
        setPerPageParam(perPage);
      } else {
        setPerPageParam("Default");
      }
      if (searchOptions.refresh) {
        refresh = parseInt(searchOptions.refresh);
        setRefreshParam(refresh);
      } else {
        setRefreshParam("Default");
      }
      if (searchOptions.panel) {
        if (searchOptions.panel === "hidden") {
          setPanel(false);
        }
      }
      if (searchOptions.live) {
        live = searchOptions.live;
        setLiveParam(live);
      } else {
        setLiveParam("true");
      }
      if (searchOptions.abstractLiveshotName) {
        abstractLiveshotName = searchOptions.abstractLiveshotName;
        setAbstractLiveshotNameParam(abstractLiveshotName);
      }
      if (searchOptions.stId) {
        stId = searchOptions.stId;
        setStIdParam(stId);
      }
      if (searchOptions.view) {
        view = searchOptions.view;
        if (view === "control-room") {
          setControlRoomViewParam(true);
        } else {
          setControlRoomViewParam(false);
        }
      }
      if (searchOptions.filter) {
        if (searchOptions.filter === "true") {
          setFilterParam(true);
          setDefaultcolDef({
            sortable: true,
            filter: true,
            floatingFilter: true,
            cellStyle: { border: "none !important" },
          });
        } else {
          setDefaultcolDef({
            sortable: true,
            filter: true,
            floatingFilter: false,
            cellStyle: { border: "none !important" },
          });
        }
      }
      if (searchOptions.timeFormat) {
        timeFormat = searchOptions.timeFormat;
        if (timeFormat === "12h") {
          timeFormatValue = true;
          setTimeFormat12Hour(true);
        } else {
          timeFormatValue = false;
          setTimeFormat12Hour(false);
        }
      }
      if (searchOptions.debounceInterval) {
        debounceIntervalValue = searchOptions.debounceInterval;
        setDebounceInterval(debounceIntervalValue);
      }
      if (userList.length > 0 && controlRoomList.length > 0) {
        onGetArrivalsBoardInfo(
          xApiKey,
          time,
          room,
          perPage,
          userList,
          abstractLiveshotName,
          stId,
          timeFormatValue,
          debounceIntervalValue
        );
        if (isConnectedCheckValue === "false") {
          setLiveParam(false);
          setIsSocketConnect(false);
          if (refresh > 0) {
            setInterval(
              () =>
                onGetArrivalsBoardInfo(
                  xApiKey,
                  time,
                  room,
                  perPage,
                  userList,
                  abstractLiveshotName,
                  stId,
                  timeFormatValue,
                  debounceIntervalValue
                ),
              1000 * refresh
            );
          } else if (refresh === null) {
            setInterval(
              () =>
                onGetArrivalsBoardInfo(
                  xApiKey,
                  time,
                  room,
                  perPage,
                  userList,
                  abstractLiveshotName,
                  stId,
                  timeFormatValue,
                  debounceIntervalValue
                ),
              1000 * 60 * 5
            );
          }
        } else if (isConnectedCheckValue === "true") {
          setIsSocketConnect(true);
          if (liveParam === "false") {
            if (refresh > 0) {
              setInterval(
                () =>
                  onGetArrivalsBoardInfo(
                    xApiKey,
                    time,
                    room,
                    perPage,
                    userList,
                    abstractLiveshotName,
                    stId,
                    timeFormatValue,
                    debounceIntervalValue
                  ),
                1000 * refresh
              );
            } else if (refresh === null) {
              setInterval(
                () =>
                  onGetArrivalsBoardInfo(
                    xApiKey,
                    time,
                    room,
                    perPage,
                    userList,
                    abstractLiveshotName,
                    stId,
                    timeFormatValue,
                    debounceIntervalValue
                  ),
                1000 * 60 * 5
              );
            }
          } else if (liveParam === "true") {
            if (refresh && refresh > 0) {
              setInterval(
                () =>
                  onGetArrivalsBoardInfo(
                    xApiKey,
                    time,
                    room,
                    perPage,
                    userList,
                    abstractLiveshotName,
                    stId,
                    timeFormatValue,
                    debounceIntervalValue
                  ),
                1000 * refresh
              );
            } else {
              setInterval(
                () =>
                  onGetArrivalsBoardInfo(
                    xApiKey,
                    time,
                    room,
                    perPage,
                    userList,
                    abstractLiveshotName,
                    stId,
                    timeFormatValue,
                    debounceIntervalValue
                  ),
                1000 * 60 * 5
              );
            }
          }
        }
        setLoaded(true);
        //setNotify(false);
      }
    }
  }, [
    onGetArrivalsBoardInfo,
    searchOptions,
    userList,
    liveParam,
    controlRoomList,
    isConnectedCheckValue,
  ]);

  useEffect(() => {
    if (arrivalsBoardData) {
      let filteredData = arrivalsBoardData.filter(
        (arrivalsInfo) => arrivalsInfo.hitTime != null
      );
      setFilteredArrivalData(filteredData);
    }
  }, [arrivalsBoardData]);

  const [columns, setColumns] = useState([
    {
      headerName: "CR",
      field: "roomCode",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 3,
    },
    {
      headerName: "Show",
      field: "showName",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      suppressNavigable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 5,
    },
    {
      headerName: "Block",
      field: "blockName",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      cellStyle: { "text-align": "center" },
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 2,
    },
    {
      headerName: "Window",
      field: "window",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 4,
    },
    {
      headerName: "Hit Time",
      field: "hitTimevalue",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 2,
    },
    {
      headerName: "Location",
      field: "location",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 6,
    },
    {
      headerName: "Liveshot Name",
      field: "abstractLiveshotName",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "abstractLiveshotIdToolTip",
      flex: 6,
    },
    {
      headerName: "IFB",
      field: "comms",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 3,
    },
    {
      headerName: "Global Source",
      field: "global",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "abstractLiveshotUpdated",
      flex: 4,
    },
    {
      headerName: "Source",
      field: "local",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "liveshotUpdated",
      flex: 3,
    },
    {
      headerName: "Ready",
      field: "ready",
      cellClass: "ready-cell",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important", "text-align": "center" },
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 2,
    },
  ]);

  /* const DateFormater = (date) => {
    if (date === null) return;
    let Time = null;
    Time = new Date(date);
    return Time.toLocaleTimeString(Time, {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    });
  }; */

  const [defaultcolDef, setDefaultcolDef] = useState({
    flex: 1,
    sortable: true,
    filter: true,
    floatingFilter: false,
  });

  let modeMessage = "";
  if (isSocketConnect && liveParam === "true") {
    if (searchOptions && searchOptions.refresh > 0) {
      modeMessage = "Refresh: " + searchOptions.refresh + "s)";
    } else {
      modeMessage = "Refresh: 300s";
    }
  } else {
    if (searchOptions && searchOptions.refresh > 0) {
      modeMessage = "Refresh: " + searchOptions.refresh + "s";
    } else if (searchOptions && !searchOptions.hasOwnProperty("refresh")) {
      modeMessage = "Refresh: " + 300 + "s";
    } else if (liveParam === false) {
      modeMessage = "Refresh: " + 300 + "s";
    }
  }
  const onChangeRefresh = (e, key) => {
    if (e.target.value !== "") {
      if (key === "refresh") {
        setRefreshParam(e.target.value);
      } else {
        setPerPageParam(e.target.value);
      }
      if (e.target.value === "Default") {
        onDeleteQueryStringParameter(key);
      } else if (e.target.value === "Others") {
      } else {
        let value = e.target.value;
        updateQueryStringParameter(key, value);
      }
    }
  };
  const onChangeTime = (e, key) => {
    e.preventDefault();
    if (key === "sub") {
      let utcValue;
      if (searchOptions.time) {
        let queryTime = searchOptions.time;
        let time = new Date(queryTime);
        time.setUTCHours(time.getUTCHours() - 2);
        utcValue = time.toISOString().split(".")[0] + "Z";
      } else {
        let time = new Date();
        time.setUTCHours(time.getUTCHours() - 2);
        utcValue = time.toISOString().split(".")[0] + "Z";
      }
      updateQueryStringParameter("time", utcValue);
    } else if (key === "add") {
      let utcValue;
      if (searchOptions.time) {
        let queryTime = searchOptions.time;
        let time = new Date(queryTime);
        time.setUTCHours(time.getUTCHours() + 2);
        utcValue = time.toISOString().split(".")[0] + "Z";
      } else {
        let time = new Date();
        time.setUTCHours(time.getUTCHours() + 2);
        utcValue = time.toISOString().split(".")[0] + "Z";
      }
      updateQueryStringParameter("time", utcValue);
    } else {
      if (searchOptions.time) {
        onDeleteQueryStringParameter("time");
      } else {
        let time = new Date();
        time.setUTCHours(time.getUTCHours());
        let utcValue = time.toISOString().split(".")[0] + "Z";
        updateQueryStringParameter("time", utcValue);
      }
    }
  };

  const onChangeFilter = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (value) {
      setFilterParam(true);
      updateQueryStringParameter("filter", "true");
    } else {
      setFilterParam(false);
      onDeleteQueryStringParameter("filter");
    }
  };

  const onChangeFormat = (e, key) => {
    e.preventDefault();
    if (key === "12" && timeFormat12Hour === false) {
      setTimeFormat12Hour(true);
      updateQueryStringParameter("timeFormat", "12h");
    } else if (key === "24" && timeFormat12Hour === true) {
      setTimeFormat12Hour(false);
      onDeleteQueryStringParameter("timeFormat");
    }
  };

  const updateQueryStringParameter = (key, value) => {
    let query = window.location.search;
    let uri = query.substring(1);
    let updatedQuery = uri.substring(0, 0) + "&" + uri.substring(0);
    var re = new RegExp("&" + key + "(=[^&]*)?|^" + key + "(=[^&]*)?");
    var separator = "&";
    if (updatedQuery.match(re)) {
      let queryString = updatedQuery.replace(re, separator + key + "=" + value);
      let query = queryString.substring(1);
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        query;
    } else {
      let queryString = updatedQuery + separator + key + "=" + value;
      let query = queryString.substring(1);
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        query;
    }
  };
  const onDeleteQueryStringParameter = (key) => {
    let query = window.location.search;
    let slicedQuery = query.substring(1);
    let updatedQuery =
      slicedQuery.substring(0, 0) + "&" + slicedQuery.substring(0);
    var rege = new RegExp("&" + key + "(=[^&]*)?|^" + key + "(=[^&]*)?");
    if (updatedQuery.match(rege)) {
      let newurl = updatedQuery.replace(rege, "");
      let queryString = newurl.substring(1);
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        queryString;
    }
  };

  let tableView = null;

  if (loaded === true) {
    if (width < breakpoint) {
      tableView = (
        <div className="ag-theme-alpine-dark ab_table_container">
          <AgGridReact
            columnDefs={mobileColumns}
            defaultColDef={defaultcolDef}
            rowData={filteredArrivalData}
            tooltipShowDelay={tooltipShowDelay}
            rowHeight={32}
            rowClassRules={{
              "text-gray": function (params) {
                return params.data.canceled === true;
              },
              "text-blue": function (params) {
                return params.data.readyForPcr === true;
              },
              "text-green": function (params) {
                return params.data.readyForAir === true;
              },
              "text-white": function (params) {
                return params.data.needsMediaTrafficReviewFlag === true;
              },
              "text-orange": function (params) {
                return params.data.clear === true;
              },
              "border-blue": function (params) {
                return params.data.liveshotBorder === true;
              },
            }}
          ></AgGridReact>
        </div>
      );
    } else if (controlRoomViewParam) {
      let controlRoomColumns = defaultControlRoomColumns;
      if (
        !("room" in searchOptions) ||
        searchOptions.room === null ||
        searchOptions.room === ""
      ) {
        // let sourceColumnIndex = controlRoomColumns.findIndex(x => x.headerName === 'Source');

        let newColumn = {
          headerName: "CR",
          field: "roomValue",
          resizable: true,
          sortable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          flex: 2,
          cellStyle: { textAlign: "center", border: "none !important" },
          cellRendererFramework: (Params) => {
            return Params.data.roomValue ? (
              <div className="room-cell">
                <span> {Params.data.roomValue}</span>
              </div>
            ) : null;
          },
        };
        controlRoomColumns.splice(0, 0, newColumn);
      }

      tableView = (
        <div className="ag-theme-alpine-dark ab_table_container">
          <AgGridReact
            columnDefs={controlRoomColumns}
            defaultColDef={defaultcolDef}
            rowData={filteredArrivalData}
            rowHeight={125}
            rowClassRules={{
              "border-blue": function (params) {
                return params.data.liveshotBorder === true;
              },
            }}
            tooltipShowDelay={tooltipShowDelay}
          ></AgGridReact>
        </div>
      );
    } else {
      tableView = (
        <div className="ag-theme-alpine-dark ab_table_container">
          <AgGridReact
            columnDefs={columns}
            defaultColDef={defaultcolDef}
            rowData={filteredArrivalData}
            tooltipShowDelay={tooltipShowDelay}
            rowClassRules={{
              "text-gray": function (params) {
                return params.data.canceled === true;
              },
              "text-blue": function (params) {
                return params.data.readyForPcr === true;
              },
              "text-green": function (params) {
                return params.data.readyForAir === true;
              },
              "text-white": function (params) {
                return params.data.needsMediaTrafficReviewFlag === true;
              },
              "text-orange": function (params) {
                return params.data.clear === true;
              },
              "border-blue": function (params) {
                return params.data.liveshotBorder === true;
              },
            }}
          ></AgGridReact>
        </div>
      );
    }
  }
  let panelControls = null;
  if (panel && !controlRoomViewParam) {
    if (
      width < breakpoint ||
      (roomParam && roomParam !== null && controlRoomViewParam)
    ) {
      panelControls = <div></div>;
    } else {
      panelControls = (
        <form className="navigations">
          <label className="per-page">
            Per Page:
            <select
              className="per-page"
              value={perPageParam ? perPageParam : ""}
              onChange={(e) => onChangeRefresh(e, "perPage")}
            >
              <option> Others</option>
              <option>Default</option>
              <option>30</option>
              <option>60</option>
              <option>120</option>
              <option>300</option>
            </select>
          </label>
          <button
            className="change-time"
            onClick={(e) => onChangeTime(e, "sub")}
          >
            -2h
          </button>
          <button
            className="change-time"
            onClick={(e) => onChangeTime(e, "now")}
          >
            Now
          </button>
          <button
            className="change-time"
            onClick={(e) => onChangeTime(e, "add")}
          >
            +2h
          </button>
          <span className="per-page">
            Refresh:
            <select
              className="per-page"
              value={refreshParam ? refreshParam : ""}
              onChange={(e) => onChangeRefresh(e, "refresh")}
            >
              <option>Others </option>
              <option>Default</option>
              <option>30</option>
              <option>60</option>
              <option>120</option>
              <option>300</option>
            </select>
          </span>
          <label className="search-sort">
            <input
              name="isGoing"
              type="checkbox"
              checked={filterParam}
              onChange={onChangeFilter}
            />
            Search/Sort
          </label>
          <button
            className={timeFormat12Hour ? "timeFormat-12" : "timeFormat"}
            onClick={(e) => onChangeFormat(e, "12")}
          >
            12
          </button>
          |
          <button
            className={timeFormat12Hour ? "timeFormat" : "timeFormat-24"}
            onClick={(e) => onChangeFormat(e, "24")}
          >
            24
          </button>
        </form>
      );
    }
  }
  let containerClasses = "arrivals-board-container container";
  if (controlRoomViewParam) containerClasses += " ab-control-room-view";
  return (
    <div className={containerClasses}>
      <p className="ab-heading">{title}</p>
      {panelControls}
      <p className="ab-datetime">
        <span className="ab-refresh-detail">{modeMessage}</span>
        {isSocketConnect && liveParam === "true" ? (
          <img
            className="ab-refresh-detail ab-live-indicator"
            src={liveIndicator}
            alt="Live-updating"
          />
        ) : (
          <span></span>
        )}
        <CurrentDate />
      </p>
      {tableView}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    arrivalsBoardData: state.arrivalsBoard.arrivalsBoardInfo,
    userList: state.userInfo.userList,
    notifications: state.websocketInfo.socketMsg,
    isConnectedCheck: state.websocketInfo.isConnectedCheck,
    controlRoomList: state.arrivalsBoard.controlRoomList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetArrivalsBoardInfo: (
      xApiKey,
      time,
      room,
      perPage,
      userList,
      abstractLiveshotName,
      stId,
      timeFormat12Hour,
      debounceInterval
    ) =>
      dispatch(
        actions.getArrivalsBoardInfo(
          xApiKey,
          time,
          room,
          perPage,
          userList,
          abstractLiveshotName,
          stId,
          timeFormat12Hour,
          debounceInterval
        )
      ),
    onFetchUserList: (xApiKey) => dispatch(actions.fetchUserList(xApiKey)),
    onFetchControlRoomList: () => dispatch(actions.fetchControlRoomList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
